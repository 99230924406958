.container {
  position: relative;
  display: flex;
  height: 100vh;
  left: 460px;
  width: calc(100%);
  width: calc(100%);
  padding-left: 15%;
  padding-top: 20px;
  flex-direction: column;

}

#container-filters {
  width: 100%;

  input {
    min-width: 350px;
  }
}

.form {
  padding-top: 17px;
  padding-left: 10px;


}

.container-form-control {
  display: flex;
  flex-direction: row;
}

.top-bar {
  display: flex;
  flex-direction: row;
}

.top-text {
  padding-top: 20px;
  padding-left: 20px;
}

.top-text2 {
  padding-top: 20px;
  padding-left: 10px;
}

.title {
  padding-top: 20px;
  width: 38%;
}

.top-bar2 {
  display: flex;
  flex-direction: row;
}

.top-text3 {
  padding-left: 5px;
  font-size: small;
}

.top-text4 {
  padding-left: 20px;
}

.form2 {
  padding-left: 35px;
  width: 52%;
}

.button {
  padding-top: 15px;
  padding-left: 15px;
}

.row {
  display: flex;
  flex-direction: row;
}

.button2 {
  width: 80%;
  text-align: right;
}

.info {
  display: flex;
  flex-direction: row;
}

.top-bar-collor {
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 100%;
}

.button-info {
  padding-left: 5px;
}

.top-bar3 {
  display: flex;
  flex-direction: row;
}

.curva {
  display: flex;
  flex-direction: row;
}

.icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.div-table {
  max-width: 100%;
  overflow: scroll;
}

.tr {
  background-color: gray;
  border-color: gray;
}

thead {
  position: sticky;
  top: 0px;
}

th {
  justify-content: center;
}

.container-search {
  width: 60vh;
}