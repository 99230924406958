

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  
}



.form-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin: auto;
  margin-top: 10%;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: rgb(222, 230, 214);
  height: 50vh;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
 
}

.logo {
  align-self: center;
}

.header-login {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  /* identical to box height */

  display: flex;
  align-items: flex-end;

  top: -19px;
  text-align: center;
  margin-bottom: 20%;
  font-weight: bold;
  /* identical to box height*/
  color: rgba(14, 7, 4, 0.5);
}

.button-send {
  border-radius: 10px;
  width: 100%;
  background: rgba(253, 81, 0, 0.9);
  border: 1px solid #fd5100;
}

.button-send:hover {
  background: rgba(84, 201, 80, 0.7);
 
  border-radius: 10px;
  transition: transform 0.5s;
  transform: scale(1.2);
  
 
}

.button-cancel {
  border-radius: 10px;
  width: 100%;
  background: #ccc;
  border: 1px solid #ccc;
  margin-top: 10px;
}
