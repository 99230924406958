.sidebar {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100vh;
  width: 320px;
  background-color: #e6dede;
  transition: 0.5s ease-in-out;
        
  

  .link-collapse {
    align-items: center;
    display: block;
    text-align: right;
    padding: 10px;
  }

  &__logo {
    display: grid;
    place-items: center;
    height: 120px;
    font-size: 1.5rem;
    font-weight: 700;
  }

  &__menu {
    position: relative;
    &__item {
      display: flex;
      align-items: center;
      place-content: flex-start;
      padding: 1rem 3rem;
      font-size: 1.25rem;
      font-weight: 500;
      color: #555;
      transition: color 0.3s ease-in-out;
      text-decoration: none;

      &.active { 
        
        margin-right: 1rem;
        margin-left: 1rem;
      }
      &__icon {
        margin-right: 2rem;
        i {
          font-size: 1.75rem;
        }
      }
      &__text {
        text-decoration: none;
      }
    }

    &__indicator {
      position: absolute;
      top: 0;
      left: 50%;
      width: calc(100% - 40px);
      border-radius: 10px;
      background-color: #308efe;
      z-index: -1;
      transform: translateX(-50%);
      transition: 0.3s ease-in-out;
    }
  }
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.login {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

